import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 400px;
  margin: -48px auto 0 auto;
  background-color: #000;
  box-shadow: 0 0 12px 12px rgba(0, 0, 0, 0.1);

  @media only screen and (max-width: 960px) {
    display: block;
    height: auto;
  }
`;

export const AlbumCovers = styled.div`
  flex: 1 1 auto;
  display: flex;
  position: relative;
  justify-content: space-around;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  overflow: hidden;

  > .gatsby-image-wrapper {
    flex: 1 1 200px;
    width: 200px;
    height: 100%;
    background-size: cover;
    background-position: center;
  }
`;

export const AlbumCover = styled.div`
  flex: 1 1 200px;
  width: 200px;
  height: 200px;
  background-size: cover;
  background-position: center;
`;

export const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  //z-index: 1;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  @media only screen and (max-width: 960px) {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
  }
`;

export const Content = styled.div`
  position: relative;
  z-index: 1;
  flex: 1 1 auto;
  max-width: 400px;
  padding-right: 96px;

  @media only screen and (max-width: 960px) {
    max-width: none;
    padding: 24px 0 64px;
    margin: 0 24px;
    padding-right: 0;
  }
`;

export const HeaderLeft = styled.h2`
  width: max-content;
  font-size: 2rem;
  letterspacing: 0.3rem;
  font-weight: 600;
  // font-style: italic;
  // margin-left: 72px;
  @media only screen and (max-width: 960px) {
    width: auto;
    line-height: 130%;
  }
`;
