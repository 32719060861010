import styled from "styled-components";
import { Color } from "../../../styles";

export const Container = styled.div`
  position: relative;
  z-index: 1;
  margin: 0 auto 0 auto;
  padding: 48px 0 0px 0;
  color: ${Color.TEXT_BLACK_PRIMARY};
`;

export const Columns = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: top;
  max-width: 912px;
  margin: 0 auto;

  @media only screen and (max-width: 960px) {
    display: block;
    width: auto;
    margin: 0 24px;
  }
`;

export const TextColumn = styled.div`
  flex: 1 1 33%;
  max-width: 400px;
  padding: 0 24px;
  box-sizing: border-box;

  > div {
    display: block;
    width: 80px;
    height: 80px;
    margin: 0 auto 24px;
    border: 4px solid #fff;
    border-radius: 50%;
    box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.1);
    background-size: cover;
    background-position: center;
  }
`;

export const H2 = styled.h2`
  display: block;
  margin: 0 auto 18px;
  width: max-content;
  font-size: 2rem;
  letterspacing: 0.3rem;
  font-weight: 600;
`;

export const Name = styled.p`
  display: block;
  margin-top: 12px;
  text-align: right;
`;
