import styled from "styled-components";

import { scroller } from "react-scroll";
import React from "react";

import { Color } from "../../../styles";

const ContactButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 36px;
  user-select: none;

  > span {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    margin: 0 12px;
    padding: 0 24px;
    background-color: ${Color.ORANGE_PRIMARY};
    border-radius: 24px;
    font-size: 0.875rem;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
    line-height: 140%;

    &:hover {
      background-color: ${Color.ORANGE_LIGHT};
      cursor: pointer;
    }
  }
`;

export default () => (
  <ContactButton
    onClick={() => {
      scroller.scrollTo("contact", {
        duration: 1000,
        smooth: "easeInOutQuint"
      });
      setTimeout(() => {
        document.getElementById("contactNameInput").focus();
      }, 1000);
    }}
  >
    <span>Kontakta oss för kostnadsfri konsultation</span>
  </ContactButton>
);
