import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import * as Styles from "./MusicSelection.styles";

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        albumCovers: file(relativePath: { eq: "general/albumCovers.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 2400, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => (
      <Styles.Container>
        <Styles.AlbumCovers>
          <Img
            fluid={data.albumCovers.childImageSharp.fluid}
            alt="Boka DJ Musik"
            title="Boka DJ Musik"
          />
          <Styles.Gradient />
        </Styles.AlbumCovers>

        <Styles.Content>
          <Styles.HeaderLeft>Vi spelar din musik</Styles.HeaderLeft>
          {/* <Styles.HeaderRight>din musik</Styles.HeaderRight> */}
          {children}
        </Styles.Content>
      </Styles.Container>
    )}
  />
);
