import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  z-index: 1;
  margin: -24px auto 0 auto;
  padding: 64px 0 96px 0;
  background-color: #222;
  box-shadow: 0 0 12px 12px rgba(0, 0, 0, 0.1);
  clip-path: polygon(0 0, 100% 24px, 100% calc(100% - 24px), 0 100%);
`;

export const TextContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;

  @media only screen and (max-width: 960px) {
    width: auto;
    margin: 0 24px;
  }

  > h2 {
    width: max-content;
    max-width: 100%;
    font-size: 2rem;
    letterspacing: 0.3rem;
    font-weight: 600;

    @media only screen and (max-width: 960px) {
      width: auto;
      line-height: 130%;
    }
  }
`;

export const Columns = styled.div`
  display: flex;
  flex-wrap: wrap;

  max-width: 960px;
  margin: 0px auto;
`;

export const Package = styled.div`
  flex: 1 1 50%;
  padding: 0 24px;
  box-sizing: border-box;
`;

export const PackagePreview = styled.img`
  display: block;
  width: 100%;
  margin: 0 auto 24px auto;
`;

export const PackageName = styled.h4`
  margin: 24px 24px;
  width: max-content;
  font-size: 1.5rem;
  letterspacing: 0.3rem;
  font-weight: 600;
`;

export const PackageDesc = styled.p`
  margin: 0 24px;
`;

export const PackagePrice = styled.p`
  margin: 24px;
  // text-align: center;
`;
