import React from "react";

import * as Styles from "./Introduction.styles";

import ContactButton from "../../general/ContactButton/ContactButton";

export default () => (
  <Styles.Container>
    <Styles.Columns>
      <Styles.TextColumn>
        <Styles.H1>DJ till fest i Stockholm</Styles.H1>
        <p>
          Fira något speciellt - födelsedag, nytt jobb eller lyxa till vardagen?
          Oavsett finns vi här för att anordna din fest på dina villkor. Vi vet
          hur man får igång dansgolvet, oavsett sällskap. Vi tar din
          födelsedagsfest till nya höjder, sätter prägel på din
          studentmottagning och skapar karaktär på dina drömmars
          möhippa/svensexa.
        </p>
        <ContactButton />
      </Styles.TextColumn>
    </Styles.Columns>
  </Styles.Container>
);
