import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Color } from "../../../../../styles";

const Container = styled.div`
  > input {
    display: block;
    width: 100%;
    padding: 6px 8px;
    box-sizing: border-box;

    background: none;
    border: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-user-modify: read-write-plaintext-only;
    outline: none;
  }
`;

const Underline = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.3);

  > div {
    display: block;
    margin: 0 auto;
    width: 0;
    height: 2px;
    background-color: ${Color.ORANGE_PRIMARY};
    transition: width 0.3s ease-in-out;

    &.focused {
      width: 100%;
    }
  }
`;

export default class Input extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      focused: false,
    };
  }
  render() {
    return (
      <Container>
        <input
          id={this.props.id}
          type={this.props.type}
          value={this.props.value}
          placeholder={this.props.placeholder}
          onChange={this.props.onChange}
          onFocus={() => this.setState({ focused: true })}
          onBlur={() => this.setState({ focused: false })}
        />
        <Underline>
          <div className={this.state.focused ? "focused" : undefined} />
        </Underline>
      </Container>
    );
  }
}

Input.propTypes = {
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
