import React from "react";
import styled from "styled-components";
import { Element } from "react-scroll";

import { ApiService } from "../../../services/ApiService";
import { Color } from "../../../styles/";
import Input from "./components/Input/Input";
import { executeGrecaptcha } from "../../../services/GrecaptchaService";

const ScrollElement = Element;

const Container = styled.div`
  max-width: 800px;
  margin: 72px auto 48px;

  color: rgba(0, 0, 0, 0.9);

  @media only screen and (max-width: 960px) {
    width: auto;
    margin: 72px 24px 48px;
  }
`;

const H4 = styled.h4`
  display: block;
  width: max-content;
  margin: 24px 0;
  font-size: 1.5rem;
  letterspacing: 0.1rem;
  font-weight: 600;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.9);
`;

const P = styled.p`
  margin: 24px 0;
`;

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  > div {
    flex: 1 1 33%;
    padding: 0 12px;
    box-sizing: border-box;

    @media only screen and (max-width: 500px) {
      flex: 1 1 100%;
      margin-bottom: 12px;
    }
  }
`;

const SubmitButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 48px;
  user-select: none;

  > span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 48px;
    padding: 0 48px;
    background-color: ${Color.ORANGE_PRIMARY};
    border-radius: 24px;
    font-size: 0.875rem;
    text-transform: uppercase;
    font-weight: 600;
    color: ${Color.TEXT_WHITE_PRIMARY};

    &:hover {
      background-color: ${Color.ORANGE_LIGHT};
      cursor: pointer;
    }
  }

  &.sending > span {
    background-color: rgba(0, 0, 0, 0.5);
    color: rgba(255, 255, 255, 0.5);
  }

  &.sent > span {
    background-color: ${Color.GREEN_PRIMARY};
    &:hover {
      cursor: default;
    }
  }
`;

const Contact = () => {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [sendStatus, setSendStatus] = React.useState("IDLE");

  const postBooking = () => {
    if (sendStatus === "PENDING" || sendStatus === "SUCCESS") {
      return;
    }
    setSendStatus("PENDING");

    executeGrecaptcha("book")
      .then((token) => {
        ApiService.post("/bookings/", {
          name,
          email,
          phone_number: phoneNumber,
          grecaptcha_token: token,
        })
          .then((response) => {
            setSendStatus("SUCCESS");
          })
          .catch(() => {
            alert("Någinting gick fel :(");
            setSendStatus("IDLE");
          });
      })
      .catch(() => {
        alert("Någinting gick fel :(");
        setSendStatus("IDLE");
      });
  };

  return (
    <Container>
      <ScrollElement name="contact" />
      <H4>Kontakta oss</H4>
      <P>
        Fyll i era kontaktuppgifter nedan så kontaktar vi er för en kostnadsfri
        konsultation.
      </P>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Namn"
          id="contactNameInput"
        />
        <Input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-post"
        />
        <Input
          type="tel"
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          placeholder="Telefon"
        />
      </Form>
      <SubmitButton
        className={
          sendStatus === "SUCCESS"
            ? "sent"
            : sendStatus === "PENDING"
            ? "sending"
            : ""
        }
        onClick={() => postBooking()}
      >
        <span>{sendStatus === "SUCCESS" ? "Skickat" : "Skicka"}</span>
      </SubmitButton>
    </Container>
  );
};

export default Contact;
