import React from "react";

import * as Styles from "./Packages.styles";

import basePackageImage from "./assets/base.svg";
import extendedPackageImage from "./assets/extended.svg";

import ContactButton from "../ContactButton/ContactButton";

export default () => (
  <Styles.Container>
    <Styles.TextContainer>
      <h2>Prisexempel</h2>
      <p>
        Vi anpassar alltid våra priser till typ av spelning och ser till att
        hitta en lösning som passar perfekt för dig.
      </p>
    </Styles.TextContainer>
    <Styles.Columns>
      <Styles.Package>
        <Styles.PackagePreview src={basePackageImage} alt="DJ Baspaket" />
        <Styles.PackageName>Baspaket</Styles.PackageName>
        <Styles.PackageDesc>Perfekt för den mindre festen.</Styles.PackageDesc>
        <Styles.PackagePrice>Från: 3 700 kr</Styles.PackagePrice>
      </Styles.Package>
      <Styles.Package>
        <Styles.PackagePreview src={extendedPackageImage} alt="DJ Utökat" />
        <Styles.PackageName>Utökat/Festpaket</Styles.PackageName>
        <Styles.PackageDesc>
          Allt du behöver för en garanterat lyckad fest.
        </Styles.PackageDesc>
        <Styles.PackagePrice>Från: 4 500 kr</Styles.PackagePrice>
      </Styles.Package>
    </Styles.Columns>
    <ContactButton />
  </Styles.Container>
);
