import React from "react";

import Header from "../components/general/Header/Header";
import Footer from "../components/general/Footer/Footer";
import Contact from "../components/general/Contact/Contact";

import video from "../assets/videos/bannerVideoCompressed3.mp4";

import Banner from "../components/general/Banner/Banner";
import Introduction from "../components/privatfest/Introduction/Introduction";
import MusicSelection from "../components/general/MusicSelection/MusicSelection";
import UserStories from "../components/privatfest/UserStories/UserStories";
import Packages from "../components/general/Packages/Packages";
import CustomHelmet from "../components/general/CustomHelmet/CustomHelmet";

export default () => (
  <div>
    <CustomHelmet
      title="Privatfest hyra DJ Stockholm - Platoon DJs"
      description="Privatfest hyra DJ i Stockholm"
      keywords="dj, fest, privatfest, hyra, stockholm"
      canonical="/privatfest/"
    />
    <Header />
    <Banner videoUrl={video} tagline="Rätt musik, rätt stämning" />
    <Introduction />
    <MusicSelection>
      <p>
        Carola eller Drake, vi har garanterat en DJ som kan leverera en
        oförglömlig kväll för dig och dina gäster, oavsett musiksmak.
      </p>
    </MusicSelection>
    <Packages />
    <UserStories />
    <Contact />
    <Footer />
  </div>
);
