import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  z-index: 1;
  margin: 0 auto;
  padding: 64px 0 96px 0;
  background-color: #222;
  box-shadow: 0 0 12px 12px rgba(0, 0, 0, 0.1);
  clip-path: polygon(0 0, 100% 24px, 100% calc(100% - 24px), 0 100%);
`;

export const Columns = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 912px;
  margin: 0 auto;

  @media only screen and (max-width: 960px) {
    width: auto;
    margin: 0 24px;
  }
`;

export const TextColumn = styled.div`
  flex: 1 1 460px;
  margin-right: 60px;

  @media only screen and (max-width: 960px) {
    margin: 12px 0;
  }
`;

export const H1 = styled.h1`
  width: max-content;
  font-size: 2rem;
  letterspacing: 0.3rem;
  font-weight: 600;
  @media only screen and (max-width: 960px) {
    width: auto;
    line-height: 130%;
  }
`;
